import { authLogout, authStartLogout } from 'fm3/actions/authActions';
import { toastsAdd } from 'fm3/actions/toastsActions';
import { httpRequest } from 'fm3/httpRequest';
import { Processor } from 'fm3/middlewares/processorMiddleware';

export const authLogoutProcessor: Processor = {
  actionCreator: authStartLogout,
  id: 'lcd',
  errorKey: 'auth.logOut.error',
  async handle({ dispatch, getState }) {
    try {
      FB.logout();
    } catch {
      // ignore
    }

    await httpRequest({
      getState,
      method: 'post',
      url: '/auth/logout',
      expectedStatus: [204, 401],
    });

    dispatch(authLogout());

    dispatch(
      toastsAdd({
        id: 'lcd',
        messageKey: 'auth.logOut.success',
        style: 'info',
        timeout: 5000,
      }),
    );
  },
};
